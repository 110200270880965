import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation } from "./components/navigation";
import JsonData from "./data/data.json";
import "./App.css";
import { Home } from "./components/Home";
// import WorkerForm from "./components/WorkerForm";
import EmployeeForm from "./components/WorkerForm";


const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  const [positions, setPositions] = useState([]);
  const [results, setResults] = useState([]);
  const [results_child_prfession, setResults_Child_Professions] = useState([]);

  useEffect(() => {
    fetchPositions(); // Llama a la función fetchPositions cuando el componente se monta
  }, []);
  const fetchPositions = async () => {
    try {
      const response = await fetch("https://hcfix.com/api/profession"); // Realiza la solicitud a la API
      const data = await response.json(); // Convierte la respuesta en formato JSON
      setPositions(data.Profession); // Actualiza el estado con las posiciones recuperadas
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const fetchData = async (option) => {
    try {
      const response = await fetch(
        `https://hcfix.com/api/childProfession?id_profession=${option}`
      );
      const data = await response.json();
      setResults_Child_Professions([]);
      setResults(data.data);
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };

  const fetchDataChild = async (option) => {
    try {
      const response = await fetch(
        `https://hcfix.com/api/subChildProfession?id_profession=${option}`
      );
      const data = await response.json();
      console.log(data);
      setResults([]);
      setResults_Child_Professions(data.record);
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };
  const handleOptions = (option) => {
    fetchData(option);
  };
  const handleChildOption = (option) => {
    fetchDataChild(option);
  };
  return (
    <div>
    <Router>
      <div className="App">
        <Navigation positions={positions} onOptionClick={handleOptions} />
        <main>
          <Routes>
            <Route path="/" element={<Home handleChildOption={handleChildOption} results_child_prfession={results_child_prfession} landingPageData={landingPageData} results={results}/>} />
            <Route path="/worker" element={<EmployeeForm />} />
          </Routes>
        </main>
      </div>
    </Router>
    </div>
  );
};

export default App;
