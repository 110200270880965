import React from "react";
export const Navigation = ({ positions, onOptionClick }) => {
  const handleClick = (option) => {
    onOptionClick(option);
  };
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
            I Serv
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {positions.map((position) => (
              <li onClick={() => handleClick(position.id)} key={position.id}>
                <a href="#results">{position.name}</a>
              </li>
            ))}
            <a className="navbar-brand page-scroll" href="/worker">
              Registro...
            </a>{" "}
          </ul>
        </div>
      </div>
    </nav>
    // <nav>
    //   <ul>
    //     <li>
    //       <Link to="/">Inicio</Link>
    //     </li>
    //     <li>
    //       <Link to="/worker">Registrar Trabajador</Link>
    //     </li>
    //   </ul>
    // </nav>
  );
};
