import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const WorkerForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    profession: '',
    subProfession: 0
  });

  const [professions, setProfessions] = useState([]);
  const [subProfessions, setSubProfessions] = useState([]);

  useEffect(() => {
    const fetchProfessions = async () => {
      try {
        const response = await axios.get('https://hcfix.com/api/profession');
        if (response.data.mensaje === "correcto") {
          setProfessions(response.data.Profession);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se pudieron cargar las profesiones',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Hubo un problema al cargar las profesiones',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    };

    fetchProfessions();
  }, []);

  useEffect(() => {
    const fetchSubProfessions = async () => {
      if (formData.profession) {
        try {
          const response = await axios.get(`https://hcfix.com/api/childProfession?id_profession=${formData.profession}`);
          console.log(response.data.data);
          if (response.data.mensaje === "correcto") {
            setSubProfessions(response.data.data);
          } else {
            Swal.fire({
              title: 'Error',
              text: 'No se pudieron cargar los sub empleados',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'Hubo un problema al cargar los sub empleados',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      }
    };

    fetchSubProfessions();
  }, [formData.profession]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://hcfix.com/api/subchildprofession', formData);
      if (response.status === 201) {
        Swal.fire({
          title: 'Registro Exitoso',
          text: 'El trabajador ha sido registrado correctamente',
          icon: 'success',
          confirmButtonText: 'OK'
        });

        // Limpiar el formulario
        setFormData({
          name: '',
          phone: '',
          profession: '',
          subProfession: ''
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al registrar el trabajador',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <form className='form-container' onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">Nombre</label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone">Teléfono</label>
        <input
          type="text"
          className="form-control"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="profession">Profesión</label>
        <select
          className="form-control"
          id="profession"
          name="profession"
          value={formData.profession}
          onChange={handleChange}
          required
        >
          <option value="">Seleccione una profesión</option>
          {professions.map(profession => (
            <option key={profession.id} value={profession.id}>
              {profession.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="subProfession">Sub Profesión</label>
        <select
          className="form-control"
          id="subProfession"
          name="subProfession"
          value={formData.subProfession}
          onChange={handleChange}
          required
        >
          <option value="">Seleccione una sub profesión</option>
          {subProfessions.map(subProfession => (
            <option key={subProfession.id} value={subProfession.id}>
              {subProfession.name}
            </option>
          ))}
        </select>
      </div>
      <button type="submit" className="btn btn-primary">Registrar</button>
    </form>
  );
};

export default WorkerForm;
