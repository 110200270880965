import React from "react";
import { Header } from "../components/header";
import { About } from "../components/about";
import { Gallery } from "../components/gallery";
import { Contact } from "../components/contact";
import { Results } from "../components/results";
import { ResultsChildProfession } from "../components/results-child-profession";

export const Home = ({handleChildOption,results_child_prfession,landingPageData,results}) => {
  
  return (
    <div>
      <Header data={landingPageData.Header} />
      <Results data={results} onOptionClick={handleChildOption} />
      <ResultsChildProfession data={results_child_prfession} />
      <About data={landingPageData.About} />
      <Gallery data={landingPageData.Gallery} />
      <Contact data={landingPageData.Contact} />{" "}
    </div>
  );
};
